import { IAppReducerState, IReduxReducer } from 'common/types/redux/IReducer';
import {
   SET_APP_LOADER,
   SET_APP_ERROR,
   SET_APP_NOTIFICATION,
   SET_APP_USER,
   SET_APP_LOADER_MESSAGE,
   SET_APP_TENANTS,
   SET_GLOBAL_ADMIN_TENANT,
   RESET_INITIAL_STATE_APP,
   SET_APP_TENANTS_ALL,
   SET_APP_CLIENTS_ALL,
   SET_PROGGRESS_BAR,
   SET_CANCEL_DOWNLOAD_CALLBACK
} from '../constants/appConstants'

const initialState = {
   isProggressbarActive: {active:false, text:0, dashoffset: '256px'},
   isLoading: false,
   error: null,
   notification: { type: '', message: 'success' },
   currentUser: undefined,
   loaderMessage: '',
   tenantsList: [],
   tenantsListAll: [],
   globalAdminTenant: '',
   clientList: [],
   cancelDownloadCallback: null as (() => void) | null,
} as IAppReducerState

const reducer = (state = initialState, { payload, type }: IReduxReducer) => {
   
    switch (type) {
        case SET_APP_LOADER:
           return {
                ...state,
                isLoading: payload,
           }
        case SET_APP_ERROR:
           return {
                ...state,
                error: payload,
           }
        case SET_APP_NOTIFICATION:
           return {
                ...state,
                notification: payload,
           }
        case SET_APP_USER:
           return {
                ...state,
                currentUser: payload,
           }
        case SET_APP_LOADER_MESSAGE:
           return {
               ...state,
               loaderMessage: payload,
           }
        case SET_APP_TENANTS:
           return {
               ...state,
               tenantsList: payload,
           }
        case SET_GLOBAL_ADMIN_TENANT:
           return {
               ...state,
               globalAdminTenant: payload,
           }
        case SET_APP_TENANTS_ALL:
           return {
               ...state,
               tenantsListAll: payload,
           }
        case RESET_INITIAL_STATE_APP:
           return {
               ...initialState,
           }
        case SET_APP_CLIENTS_ALL:
             return {
                ...state,
                clientList: payload,
             }
        case SET_PROGGRESS_BAR:
            return {
                ...state,
                isProggressbarActive: payload,
            }
         case SET_CANCEL_DOWNLOAD_CALLBACK:
            return { ...state, cancelDownloadCallback: payload };
        default:
            return state;
    }
};

export default reducer;