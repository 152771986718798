const SET_APP_LOADER = 'SET_APP_LOADER';
const SET_APP_ERROR = 'SET_APP_ERROR';
const SET_APP_NOTIFICATION = 'SET_APP_NOTIFICATION';
const SET_APP_USER = 'SET_APP_USER';
const SET_APP_LOADER_MESSAGE = 'SET_APP_LOADER_MESSAGE';
const SET_APP_TENANTS = 'SET_APP_TENANTS';
const SET_GLOBAL_ADMIN_TENANT = 'SET_GLOBAL_ADMIN_TENANT';
const RESET_INITIAL_STATE_APP = 'RESET_INITIAL_STATE_APP';
const SET_APP_TENANTS_ALL = 'SET_APP_TENANTS_ALL';
const SET_APP_CLIENTS_ALL = 'SET_APP_CLIENTS_ALL';
const SET_PROGGRESS_BAR = 'SET_PROGGRESS_BAR';
const SET_CANCEL_DOWNLOAD_CALLBACK = 'SET_CANCEL_DOWNLOAD_CALLBACK';
const SIDE_MODS_VOCAVULARY = [
    {
      value: "8c60f693-bef5-e011-a485-80ee7300c693",
      label: "Maintenance"
    },
    {
      value: "9c60f693-bef5-e011-a485-80ee7300c693",
      label: "Live"
    },
    {
      value: "7c60f693-bef5-e011-a485-80ee7300c693",
      label: "Preview"
    },
    {
      value: "6c60f693-bef5-e011-a485-80ee7300c693",
      label: "Development"
    },
    {
      value: "5c60f693-bef5-e011-a485-80ee7300c693",
      label: "Disabled"
    },
    {
      value: "4c60f693-bef5-e011-a485-80ee7300c693",
      label: "Trash"
    },
    {
      value: "3c60f693-bef5-e011-a485-80ee7300c693",
      label: "Draft"
    }
]
export {
    SET_APP_LOADER,
    SET_APP_ERROR,
    SET_APP_NOTIFICATION,
    SET_APP_USER,
    SET_APP_LOADER_MESSAGE,
    SET_APP_TENANTS,
    SET_GLOBAL_ADMIN_TENANT,
    RESET_INITIAL_STATE_APP,
    SET_APP_TENANTS_ALL,
    SET_APP_CLIENTS_ALL,
    SET_PROGGRESS_BAR,
    SET_CANCEL_DOWNLOAD_CALLBACK,
    SIDE_MODS_VOCAVULARY
}